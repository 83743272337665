import api from './api';

class RelacionService {
  
  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/relaciones${cadenaBusqueda}`);
  }

  getItem(layout_id, id) {
    return api.get(`/api/layouts/${layout_id}/relaciones/${id}`);
  }

  newItem(layout_id, params) {
    return api.post(`/api/layouts/${layout_id}/relaciones`, params)
  }

  editItem(layout_id, params) {
    //console.log(`/api/layouts/${layout_id}/relaciones/${params.id}`,params)
    return api.put(`/api/layouts/${layout_id}/relaciones/${params.id}`, params)
  }
}

export default new RelacionService();